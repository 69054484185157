<template>
  <div class="custom_component_container">
    <div class="custom_form_top">
      <div class="custom_form_title">
        {{ currentComponentName }}
      </div>
      <el-button class="saveSetting" type="primary" @click="saveModelSettingHandle">保存</el-button>
    </div>
    <div class="custom_form_content_box">
      <div class="left-board">
        <el-scrollbar class="left-scrollbar">
          <div class="components-list">
            <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
              <div class="components-title">
                {{ item.title }}
              </div>
              <draggable
                class="components-draggable"
                :list="item.list"
                :group="{ name: 'componentsGroup', pull: 'clone', put: false }"
                :clone="cloneComponent"
                draggable=".components-item"
                :sort="false"
                @end="onEnd"
              >
                <div
                  v-for="(element, index) in item.list"
                  :key="index"
                  class="components-item"
                  @click="addComponent(element)"
                >
                  <div class="components-body">
                    <i :class="[element.__config__.tagIcon, 'iconfont']"></i>
                    {{ element.__config__.label }}
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="center-board">
        <div class="center-scrollbar">
          <el-form
            :size="formConf.size"
            :label-position="formConf.labelPosition"
            :label-width="formConf.labelWidth + 'px'"
          >
            <draggable
              class="drawing-board"
              :list="drawingList"
              group="componentsGroup"
              :fallbackOnBody= "true"
              :swapThreshold= "0.65"
              direction="vertical"
            >
              <draggable-item
                v-for="(item, index) in drawingList"
                :key="item.renderKey"
                :drawing-list="drawingList"
                :current-item="item"
                :index="index"
                :active-id="activeId"
                :form-conf="formConf"
                @activeItem="activeFormItem"
                @deleteItem="drawingItemDelete"
              />
            </draggable>
            <div v-show="!drawingList || !drawingList.length" class="empty-info">从左侧拖入或点选组件进行组件设计</div>
          </el-form>
        </div>
      </div>
      <right-panel
        :active-data="activeData"
        :form-conf="formConf"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import draggable from 'vuedraggable';
import RightPanel from './RightPanel2';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import {
  inputComponents,
  selectComponents,
  customComponents,
  layoutComponents,
  formConf,
} from '@/components/form-generator/generator/config';
import DraggableItem from './DraggableItem';
import { getIdGlobal } from '@/components/form-generator/utils/db';
import { getComponentDetail, updateComponent } from '@/api/formSetting';
let tempActiveData;
const idGlobal = getIdGlobal();

export default {
  name: 'component_setting_index',
  components: {
    draggable,
    RightPanel,
    DraggableItem,
  },
  directives: { Clickoutside },
  props: {
    id: {
      type: String,
    },
  },
  data () {
    let componentFilter = t=>{
      let config = t.__config__;
      if (config) {
        if (config.tag === 'el-time-picker') {
          return true;
        }
        if (config.tag === 'el-date-picker') {
          return true;
        }
        return ['el-input', 'span', 'el-input-number', 'el-checkbox-group', 'el-radio-group', 'el-select'].indexOf(t.__config__.tag) >= 0;
      }
      return false;
    };
    return {
      idGlobal,
      formConf: _.cloneDeep(formConf),
      labelWidth: 100,
      drawingList: [],
      activeId: null,
      activeData: null,
      leftComponents: [
        {
          title: '自由控件',
          list: inputComponents.concat(selectComponents).filter(componentFilter),
        },
        {
          title: '系统控件',
          list: customComponents.filter(t=>['c-department-selector', 'c-user-selector', 'c-customer-selector', 'c-contact-selector', 'c-template-selector', 'c-device-selector', 'c-archive-collection'].indexOf(t.__config__.tag) >= 0),
        },
      ],
      currentComponentName: '',
    };
  },
  provide () {
    return {
      getFieldList: () => this.drawingList,
    };
  },
  created () {
    this.queryComponentDetail();
  },
  methods: {
    checkConfigIsEdited () {
      return JSON.stringify(this.oldConfigBackUp) !== JSON.stringify(this.drawingList);
    },
    queryComponentDetail () {
      getComponentDetail({ id: this.id }).then(rs => {
        let { customizeLayoutResDTO } = rs.body;
        this.drawingList = customizeLayoutResDTO?.schemaDesc?.__config__?.children[0]?.fields || [];
        this.oldConfigBackUp = _.cloneDeep(this.drawingList);
        this.currentComponentName = rs.body?.customizeBusinessComponentResDTO?.name;
        let activedItem = this.drawingList.find(t=>t.__config__.renderKey === this.activeId);
        this.activeFormItem(activedItem);
      });
    },
    saveModelSettingHandle () {
      // 校验分组名称合法(不能重复)
      if (this.drawingList?.length) {
        let curRowLabels = this.drawingList.map(i => i.__config__.label);
        if (curRowLabels.length !== [...new Set(curRowLabels)].length) {
          return this.$message.error('组件标题不能重复');
        }
      } else {
        this.$message.error('组件布局不能为空');
        return;
      }
      this.formConf.fields = this.drawingList;
      let tableColumnLayout = _.cloneDeep(layoutComponents.find(t=>t.__config__?.layout === 'tableColumnFormItem'));
      tableColumnLayout.__config__.children = [this.formConf];
      tableColumnLayout.__config__.label = this.currentComponentName;
      tableColumnLayout.id = this.id;
      const params = {
        id: this.id,
        schemaDesc: tableColumnLayout,
      };
      updateComponent(params).then(() => {
        this.$message.success('保存成功');
        this.queryComponentDetail();
      });
    },
    activeFormItem (currentItem) {
      this.activeData = currentItem;
      this.activeId = currentItem?.__config__?.renderKey;
    },
    onEnd (obj) {
      if (obj.from !== obj.to) {
        this.activeData = tempActiveData;
        this.activeId = this.idGlobal;
      }
    },
    addComponent (item) {
      const clone = this.cloneComponent(item);
      this.drawingList.push(clone);
      this.activeFormItem(clone);
    },
    cloneComponent (origin) {
      const clone = _.cloneDeep(origin);
      const config = clone.__config__;
      config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
      this.createIdAndKey(clone);
      clone.placeholder !== undefined && (clone.placeholder += config.label);
      tempActiveData = clone;
      return tempActiveData;
    },
    createIdAndKey (item) {
      const config = item.__config__;
      config.renderKey = `${new Date().getTime()}`; // 改变renderKey后可以实现强制更新组件
      if (config.layout === 'colFormItem') {
        item.__vModel__ = `field${this.idGlobal}`;
      }
      return item;
    },
    AssembleFormData () {
      let config = {
        fields: _.cloneDeep(this.drawingList),
        ...this.formConf,
      };
      console.log(config);
    },
    drawingItemDelete (item) {
      let index = this.drawingList.findIndex(t=>t.__config__?.renderKey === item.__config__.renderKey);
      if (index >= 0) {
        this.drawingList.splice(index, 1);
        this.$nextTick(() => {
          this.activeFormItem();
        });
      }
    },
  },
  beforePageLeave (tab, type) {
    if (type === 'unload' && this.checkConfigIsEdited()) {
      return 'unload';
    }
    if (type === 'replace') {
      if (this.checkConfigIsEdited()) {
        return this.$confirm('当前页面尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false }).then(()=>{
          return Promise.resolve(()=>this.$router.push(tab.to));
        }).catch(()=>Promise.reject());
      } else {
        return Promise.resolve();
      }
    } else {
      if (this.checkConfigIsEdited()) {
        return this.$confirm('当前页面尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false });
      } else {
        return Promise.resolve();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$selectedColor: #f6f7ff;
$lighterBlue: #409eff;
$handleBtn: #0f89ff;

.custom_component_container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  .custom_form_top {
    position: relative;
    height: 48px;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid #dcdfe6;
    .custom_form_title {
      height: 100%;
      line-height: 48px;
      text-align: center;
      color: #242832;
      font-size: 14px;
      .editBtn {
        font-size: 18px;
        margin-left: 18px;
        color: #aaa;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    .saveSetting {
      position: absolute;
      right: 20px;
      top: 11px;
      padding: 6px 24px;
    }
  }
  .custom_form_content_box {
    position: relative;
    height: calc(100% - 48px);
    background: #eff1f6;
    overflow: hidden;

    .left-board {
      width: 260px;
      position: absolute;
      background: #fff;
      left: 0;
      top: 0;
      bottom: 0;

      .left-scrollbar {
        height: 100%;
        overflow: hidden;
      }

      .components-list {
        padding: 8px;
        box-sizing: border-box;
        height: 100%;

        .components-draggable {
          padding-bottom: 20px;
        }
        .components-title {
          font-size: 14px;
          color: #222;
          margin: 6px 2px;
        }

        .components-item {
          display: inline-block;
          width: 48%;
          margin: 1%;
          transition: transform 0ms !important;

          .components-body {
            padding: 8px 10px;
            background: $selectedColor;
            font-size: 12px;
            cursor: move;
            border: 1px dashed $selectedColor;
            border-radius: 3px;
            &:hover {
              border: 1px dashed #787be8;
              color: #787be8;
            }

            .iconfont {
              color: #777;
              font-size: 12px;
              width: 12px;
              height: 12px;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .center-board {
      height: 100%;
      width: auto;
      overflow-y: auto;
      margin: 0 360px 0 270px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background: #fff;

      & > .center-scrollbar {
        flex: 1;
        overflow: hidden;
        background: url('../../../../assets/imgs/iphone.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 375px;
        min-height: 750px;
        margin:0 auto;
        display: flex;
        align-items: center;
        & > .el-form {
          width: 100%;
          height: 750px;
          overflow: hidden;
          box-sizing: border-box;
          padding: 48px 25px 48px;
          position: relative;
            /*定义滚动条轨道 内阴影+圆角*/
          ::-webkit-scrollbar-track,
          ::-webkit-scrollbar-thumb,
          ::-webkit-scrollbar-thumb:hover
          {
            background: transparent;
          }

          /deep/ .drawing-board {
            box-sizing: border-box;
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
            position: relative;
            padding-top: 32px;
            & > .el-col {
              margin-bottom: 15px;
            }
            .components-body {
              padding: 0;
              margin: 0;
              font-size: 0;
            }
            .sortable-ghost {
              position: relative;
              display: block;
              overflow: hidden;
              &::before {
                content: ' ';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 3px;
                background: rgb(89, 89, 223);
                z-index: 2;
              }
            }
            .components-item.sortable-ghost {
              width: 100%;
              height: 60px;
              background-color: $selectedColor;
            }
            .el-form-item {
              margin-bottom: 0;
              margin-top: 0;
            }
            .drawing-item {
              position: relative;
              cursor: move;
              &.unfocus-bordered:not(.active-from-item) > div:first-child {
                border: 1px dashed #ccc;
              }
            }
            .drawing-row-item {
              position: relative;
              cursor: move;
              box-sizing: border-box;
              // border: 1px dashed #ccc;
              // border-radius: 3px;
              border-radius: 4px;
              border: 1px solid rgba(113,121,141,0.1600);

              padding: 0 2px;
              padding-bottom: 25px;
              .drawing-row-item {
                margin-bottom: 2px;
              }
              .el-col {
                margin-top: 15px;
              }
              & > .el-col {
                margin-top: 0;
              }
              .el-form-item {
                margin-bottom: 0;
              }
              .group-title {
                font-size: 14px;
                line-height: 26px;
                height: 26px;
                vertical-align: middle;
                color: rgba(36,40,50,0.7000);
                background: #EDEDED;
                padding-left: 24px;
                box-sizing: border-box;
                margin:0 -2px;
              }
              .group-wrapper {
                border-top: 1px solid #ccc;
                margin:0 -2px;
                min-height: 40px;
              }
            }
            .drawing-item,
            .drawing-row-item {
              box-sizing: border-box;
              &.active-from-item {
                background: $selectedColor;
                border: 2px solid $lighterBlue !important;
                border-radius: 4px;

                 & > .el-form-item {
                  background: $selectedColor;
                  border-radius: 6px;
                }
                & > .drawing-item-copy,
                & > .drawing-item-delete,
                & > .drawing-item-left-btn-list {
                  // display: initial;
                  display: inline-block;
                  vertical-align: middle;
                }
                & > .component-name {
                  color: $lighterBlue;
                }
              }
              &:hover {
                & > .el-form-item {
                  background: $selectedColor;
                  border-radius: 6px;
                }
                // & > .drawing-item-copy, & > .drawing-item-delete{
                //   display: initial;
                // }
              }
              & > .drawing-item-delete,
              & > .drawing-item-left-btn-list {
                display: none;
                position: absolute;
                top: -30px;
                // width: 22px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                border-radius: 4px;
                font-size: 12px;
                background: #0f89ff;
                cursor: pointer;
                z-index: 100;
                padding: 3px 5px;
                .el-icon-delete {
                  margin-right: 6px;
                }
              }
              & > .drawing-item-delete {
                right: 0px;
                color: #ffffff;
                background: $handleBtn;
                &:hover {
                  background: $handleBtn;
                  color: #fff;
                }

                .icon {
                  font-size: 12px;
                  margin-right: 6px;
                }
              }
              & > .drawing-item-left-btn-list {
                .icon {
                  font-size: 12px;
                  margin-right: 6px;
                }
                color: #ffffff;
                background: $handleBtn;
                &:hover {
                  background: $handleBtn;
                  color: #fff;
                }
                .left_btn_item {
                  margin: 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  color: rgba(255, 255, 255, 0.6);
                  &:hover {
                    color: rgba(255, 255, 255, 1);
                  }
                  &.active {
                    color: rgba(255, 255, 255, 1);
                  }
                }
                .left_btn_item::after {
                  color: rgba(255, 255, 255, 0.6);
                  position: relative;
                  left: 10px;
                  display: inline-block;
                  vertical-align: middle;
                  content: ' ';
                  width: 1px;
                  height: 8px;
                  background: #ccc;
                }
                .left_btn_item:last-child::after {
                  display: none;
                }
              }
            }
          }

          .empty-info {
            font-size: 14px;
            position: absolute;
            left: 0;
            right: 0;
            top:50%;
            transform: translateY(-50%);
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
